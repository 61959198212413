<template>
    <div>
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage"
        color="red"></loading>

        <form v-bind:id="'upload' + this.fileName + this.productId " class="upload">
            <input type="file" name="file" ref="file" @change="upload()">
        </form>
        
        <button @click="select()">Upload New Artwork</button>

        <div v-if="uploadedFiles.length > 0">
            Uploaded files:-
        </div>
        <div v-for="f in uploadedFiles" :key="f.index">
            {{ f }}
        </div>
    </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name : 'UploadFile',
    props : {
        productId : String,
        fileName : String
    },
    methods : {
        select : function(){
            this.$refs.file.click();
        },
        upload : function(){
            let formData = new FormData( document.getElementById( 'upload' + this.fileName + this.productId ) );

            formData.append( 'id', this.productId );
            formData.append( 'name', this.fileName );

            this.isLoading = true; //show loading screen

            /**
             * Submit form data to server
             */
            this.$http.post('https://proofs.bigprintshop.co.uk/scripts/upload-new.php', formData)
            .then(
                function(){
                    this.isLoading = false //end of loading
                    alert( 'Your file has been uploaded. The update artwork will be checked and proofs within 1 working day' )
                    this.checkForFiles();
                }, function(data){
                    //fail
                    this.isLoading = false //end of loading
                    alert( 'Something has gone wrong. Please contact us on 01562 865444' )
                    console.log(data);
                }
            );
        },
        checkForFiles:function()
        {
            let formData = new FormData();

            formData.append( 'id', this.productId );
            formData.append( 'fileName', this.fileName );

            /**
             * Submit form data to server
             */
            this.$http.post('https://proofs.bigprintshop.co.uk/scripts/check-uploads.php', formData)
            .then(
                function(data){
                    this.uploadedFiles  = Array.from(data.body)
                }, function(data){
                    //fail
                    console.log(data);
                }
            );
            }
    },
    created:function(){
        this.checkForFiles()

    },
    data:function(){
        return{
            isLoading: false,
            fullPage: true,
            uploadedFiles: []
        }
    },
    components:{
        Loading
    }
}
</script>

<style scoped>
    .upload{
        display: none;
    }
</style>