<template>
  <div id="app">
    <Header></Header>
    <Content v-if="this.loggedin && !this.approvedBool" v-bind:data="data" v-bind:devMode="devMode" v-on:approved="approved()"></Content>
    <Approved v-if="this.approvedBool && this.loggedin" v-bind:data="data" v-bind:devMode="devMode"></Approved>
    <Login v-if="!this.loggedin" v-on:loggedin="saveCredentials($event)" v-bind:devMode="devMode"></Login>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/header.vue';
import Content from './components/content.vue';
import Login from './components/login.vue';
import Footer from './components/footer.vue';
import Approved from './components/approved.vue';



export default {
  name: 'App',
  props: {
      credentials : Object,
      data: Object,
      approvedBool: Boolean
  },
  data(){
      return{
          loggedin: false,
          devMode: false
      }
  },
  components: {
    Header,
    Content,
    Approved,
    Login,
    Footer,
  },
    methods: {
        approved(){
            this.approvedBool = true;
        },
        saveCredentials(data){
            this.credentials = new FormData();

            this.credentials.append('jobNumber', data.job);
            this.credentials.append('passcode', data.code);       
            
            /**
             * Fetch customer details
             */

            if( this.devMode )
            {
            this.$http.get('https://proofs.bigprintshop.co.uk/scripts/test-data.txt')
                .then(
                    function(data){
                        this.data = JSON.parse(data.bodyText);
                        this.loggedin = true;
                        if( this.data.approved !== "false" )
                        {
                            this.approvedBool = true;
                        }
                    }, function(data){
                        //fail
                        console.log(data);
                    }
                );
            } else {
                this.$http.post('https://proofs.bigprintshop.co.uk/scripts/get-job.php', this.credentials)
                .then(
                    function(data){
                        console.log(data);
                        this.data = JSON.parse(data.bodyText);
                        this.loggedin = true;
                        if( this.data.approved !== "false" )
                        {
                            this.approvedBool = true;
                        }
                    }, function(data){
                        //fail
                        console.log(data);
                    }
                );
            }

        }
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');
    
    *{
        box-sizing: border-box;
    }

    body{
        font-family: 'Gothic A1', sans-serif;
        margin: 0px;
    }

    #app{
        display: flex;
        flex-direction: column;
        align-content: space-between;
        height: 100vh;
    }
    .warning{
        color: red;
        font-weight: 700;
    }

    .boxes{
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .box{
        margin: 1rem 3rem;
    }

    .address{
        border: solid 1px black;
        padding: 1rem;
        min-width: 15rem;
    }

    .box-title{
        text-align: center;
    }

    button{
        margin: 0.5rem;
        padding: 0.5rem;
        color: white;
        background: red;
        background-origin: padding-box;
        border: none;
        width: calc(100% - 1rem);
    }

    hr{
        border: none;
        border-top: 1px solid black;
    }

</style>
