<template>
    <div class="root">
        <hr>
<div class="left">
            <div class="about">
                <h4>About Us</h4>
                <ul>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/about-us">About Us</a>
                    </li>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/requirements-of-order">Requirements of order</a>
                    </li>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/terms-conditions">Terms &amp; Conditions</a>
                    </li>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/privacy-statement">Privacy Statement</a>
                    </li>
                </ul>
            </div>
            <div class="services">
                <h4>Services</h4>
                <ul>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/gallery">Gallery</a>
                    </li>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/design-service">Design Service</a>
                    </li>
                    <li>
                        <a href="https://www.bigprintshop.co.uk/installation">Installation</a>
                    </li>
                </ul>
            </div>
            <div class="clear"></div>
        </div>
        <div class="right">
                <div class="number">
                    Call 0800 3457516 or 01562 865444
                </div>
                <div class="social">
                    <img src="/images/yt.svg">
                    <a href="https://twitter.com/BigPrintShop01" target="_blank">
                        <img src="/images/tw.svg">
                    </a>
                    <a href="https://www.instagram.com/thebigprintshop/" target="_blank">
                        <img src="/images/in.svg">
                    </a>
                    <a href="https://www.facebook.com/BigPrintShop16" target="_blank">
                        <img src="/images/fb.svg">
                    </a>
                </div>
                <div class="contact">
                    <p>
                        Copyright © 2001-2020 Big Print Shop. All rights reserved.<br>
                        Limited No. 3959877 VAT Reg 770229044
                    </p>
                    <p>i4Media Ltd The Big Print Shop, 15-17 Coventry Street, Kidderminster, Worcestershire, DY10 2BG</p>
                </div>
                <div class="clear"></div>
        </div>
        <div class="clear"></div>
        <h4 class="center">
            <h4>Meet the Familly</h4>
        </h4>
        <div class="meet">
            <div class="big_blinds_logo">
                <a href="https://www.bigblinds.co.uk/" target="_blank"> 
                    <img src="/images/big-blinds-logo.svg" alt="Big Blinds">
                </a>
            </div>
            <div class="big_boards_logo">
                <a href="https://www.bigboards.co.uk" target="_blank">
                    <img src="/images/big-boards-logo.jpg" alt="Big Boards">
                </a>        
            </div>
            <div class="big_wallpaper_logo">
                <a href="https://www.bigwallpaper.co.uk" target="_blank">
                    <img src="/images/big-wallpaper-logo.svg" alt="Big Wallpaper">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    .root{
        margin: 1rem auto;
        width: 100%;
        max-width: 1000px;
    }

    hr{
        margin: 1rem;
    }
    .clear{
        clear: both;
    }

    .left{
        float: left;
        display: flex;
    }

    .left > div{
        padding-right: 3rem;
    }

    .right{
        float: right;
        text-align: right;
    }

    a{
        color: black;
        text-decoration: none;
    }

    li{
        list-style-type: none;
        margin: 0.3rem 0;
    }

    ul{
        margin: 0;
        padding: 0;
    }

    h4, .number{
        margin: 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .social img{
        margin: 0.2rem;
    }

    .right p{
        font-size: 0.8rem;
        color: grey;
        margin-bottom: 0.5rem;
    }

    .center{
        text-align: center;
    }

    .meet{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .meet img{
        max-width: 100%;
        padding: 0 1rem;
    }

    @media screen and (max-width: 1000px){
        .left, .right{
            display: none;
        }
    }

</style>