<template>
    <div>
        <div class="title">
            <p>Job {{ data.job }} has been approved</p>
            <p class="warning" v-if="parseFloat(data.costs.outstanding) > 0">This job is showing an amount owed. The job will not be produced until payment has been made.</p>
            <div class="boxes">
                <div class="box">
                    <p class="box-title">Invoice address</p>
                    <div class="address">
                        <tamplate v-if="data.company_name">{{ data.company_name }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line1">{{ data.invoice.line1 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line2">{{ data.invoice.line2 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line3">{{ data.invoice.line3 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line4">{{ data.invoice.line4 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.postcode">{{ data.invoice.postcode }}</tamplate>
                    </div>
                </div>
                <div class="box" v-if="data.delivery.line1">
                    <p class="box-title" v-if="data.delivery.service">{{ data.delivery.service }}</p>
                    <p class="box-title" v-else>{{ data.delivery.type }}</p>
                    <div class="address" v-if="data.delivery.line1 !== ''">

                        <template v-if="!( (data.delivery.rmv_comp_name == 'on') || (data.delivery.line1 == 'Big Print Production, Rear of unit 2') )">
                            {{ data.company_name }}<br />
                        </template>

                        <tamplate v-if="!( (!data.delivery.delivery_contact) || (data.delivery.line1 == 'Big Print Production, Rear of unit 2') )">
                            FAO {{ data.delivery.delivery_contact }}<br />
                        </tamplate>

                        <template v-if="data.delivery.line1">{{ data.delivery.line1 }}<br /></template>
                        <template v-if="data.delivery.line2">{{ data.delivery.line2 }}<br /></template>
                        <template v-if="data.delivery.line3">{{ data.delivery.line3 }}<br /></template>
                        <template v-if="data.delivery.line4">{{ data.delivery.line4 }}<br /></template>
                        <template v-if="data.delivery.postcode">{{ data.delivery.postcode }}</template>

                    </div>
                </div>
            </div>
        <div class="customer-details">
            <strong>Company Name</strong> : {{ data.name }} <br/>
            <strong>Type of account</strong> : {{ data.type }} <br/>
            <strong>Company Number</strong> : {{ data.customer }} <br/>
            <strong>Job Number</strong> : {{ data.job }} <br/>
            <strong>Designer</strong>: {{ data.designer }} <br/>
            <strong>Contact E-mail Address</strong>: {{ data.email }}<br/>
            <strong>Contact Phone Number</strong>: {{ data.phone }}<br/>
        </div>
        <div class="prices">
            <div class="headings">
                <div class="qty">
                    Qty
                </div>
                <div class="name">
                    Product
                </div>
                <div class="cost">
                    Line Cost
                </div>
            </div>
            <div class="product" v-for="product in data.products" :key="product.id">
                <div class="qty">
                    {{ product.qty }} x
                </div>
                <div class="name">
                    {{ product.product_name }} {{ product.size_notes }}
                </div>
                <div class="cost">
                    {{ currency( product.line_cost ) }}
                </div>
            </div>

            <hr />

            <div v-if="parseFloat(data.costs.artwork) > 0" class="product">
                <div class="qty">
                    
                </div>
                <div class="name headings">
                    Studio Time
                </div>
                <div class="cost">
                    {{ currency( parseFloat(data.costs.artwork).toFixed(2) ) }}
                </div>
            </div>

            <div v-if="parseFloat(data.costs.discount) > 0" class="product">
                <div class="qty">
                    
                </div>
                <div class="name headings">
                    Discount
                </div>
                <div class="cost">
                    -{{ currency( parseFloat(data.costs.discount * data.costs.line).toFixed(2) ) }}
                </div>
            </div>

            <div v-if="parseFloat(data.costs.delivery) > 0" class="product">
                <div class="qty">
                    
                </div>
                <div class="name headings">
                    Delivery
                </div>
                <div class="cost">
                    {{ currency( data.costs.delivery ) }}
                </div>
            </div>

            <div class="product">
                <div class="qty"></div>
                <div class="name headings">
                    Subtotal <span class="small">(total before VAT)</span>
                </div>
                <div class="cost">
                    {{ currency( data.costs.total ) }}
                </div>
            </div>

            <div class="product">
                <div class="qty"></div>
                <div class="name headings">
                    VAT
                </div>
                <div class="cost">
                    {{ currency( data.costs.vat ) }}
                </div>
            </div>

            <div class="product" v-if="parseInt( data.costs.limit ) > 0">
                <div class="qty"></div>
                <div class="name headings">
                    Total inc VAT
                </div>
                <div class="cost">
                    {{ currency( data.costs.totalInc ) }}
                </div>
            </div>

            <div class="product">
                <div class="qty"></div>
                <div class="name headings">
                    Credit <span class="small">(amount pre-paid)</span>
                </div>
                <div class="cost">
                    {{ currency( data.costs.credit ) }}
                </div>
            </div>

            <div class="product" v-if="parseInt( data.costs.limit ) > 0">
                <div class="qty"></div>
                <div class="name headings">
                    Account limit
                </div>
                <div class="cost">
                    {{ currency(data.costs.limit) }}
                </div>
            </div>

            <div class="product" v-if="parseInt( data.costs.limit ) > 0">
                <div class="qty"></div>
                <div class="name headings">
                    Amount balance <span class="small">(amount owed)</span>
                </div>
                <div class="cost">
                    {{ currency( data.costs.balance ) }}
                </div>
            </div>

            <div class="product" v-if="parseInt( data.costs.limit ) > 0">
                <div class="qty"></div>
                <div class="name headings">
                    Amount current spent on account
                </div>
                <div class="cost">
                    {{ currency( data.costs.balance ) }}
                </div>
            </div>
        </div>

        <hr class="double"/>

        <div class="product">
            <div class="qty"></div>
            <div class="name headings">
                Total Payable
            </div>
            <div class="cost">
                {{ currency(data.costs.outstanding) }}
            </div>
        </div>

        <div class="product center" v-if="parseInt(payment.AMOUNT) > 0">
            <form action="https://pay.realexpayments.com/pay" method="POST" target="iframe">
            <input type="hidden" name="TIMESTAMP" v-bind:value="payment.TIMESTAMP">
            <input type="hidden" name="MERCHANT_ID" v-bind:value="payment.MERCHANT_ID">
            <input type="hidden" name="ACCOUNT" v-bind:value="payment.ACCOUNT">
            <input type="hidden" name="ORDER_ID" v-bind:value="payment.ORDER_ID">
            <input type="hidden" name="AMOUNT" v-bind:value="payment.AMOUNT">
            <input type="hidden" name="CURRENCY" v-bind:value="payment.CURRENCY">
            <input type="hidden" name="AUTO_SETTLE_FLAG" v-bind:value="payment.AUTO_SETTLE_FLAG">
            <input type="hidden" name="HPP_VERSION" v-bind:value="payment.HPP_VERSION">
            <input type="hidden" name="HPP_CHANNEL" value="ECOM">
            <input type="hidden" name="HPP_LANG" value="en">
            <!-- Begin 3D Secure 2 Mandatory and Recommended Fields -->
            <input type="hidden" name="HPP_CUSTOMER_EMAIL" v-bind:value="payment.HPP_CUSTOMER_EMAIL">
            <input type="hidden" name="HPP_CUSTOMER_PHONENUMBER_MOBILE" v-blind:value="payment.HPP_CUSTOMER_PHONENUMBER_MOBILE">
            <input type="hidden" name="HPP_BILLING_STREET1" v-bind:value="payment.HPP_BILLING_STREET1">
            <input type="hidden" name="HPP_BILLING_STREET2" v-bind:value="payment.HPP_BILLING_STREET2">
            <input type="hidden" name="HPP_BILLING_STREET3" v-bind:value="payment.HPP_BILLING_STREET3">
            <input type="hidden" name="HPP_BILLING_CITY" v-bind:value="payment.HPP_BILLING_CITY">
            <input type="hidden" name="HPP_BILLING_POSTALCODE" v-bind:value="payment.HPP_BILLING_POSTALCODE">
            <input type="hidden" name="HPP_BILLING_COUNTRY" v-bind:value="payment.HPP_BILLING_COUNTRY">
            <input type="hidden" name="HPP_SHIPPING_STREET1" v-bind:value="payment.HPP_SHIPPING_STREET1">
            <input type="hidden" name="HPP_SHIPPING_STREET2" v-bind:value="payment.HPP_SHIPPING_STREET2">
            <input type="hidden" name="HPP_SHIPPING_STREET3" v-bind:value="payment.HPP_SHIPPING_STREET3">
            <input type="hidden" name="HPP_SHIPPING_CITY" v-bind:value="payment.HPP_SHIPPING_CITY">
            <!--<input type="hidden" name="HPP_SHIPPING_STATE" v-bind:value="IL">-->
            <input type="hidden" name="HPP_SHIPPING_POSTALCODE" v-bind:value="payment.HPP_SHIPPING_POSTALCODE">
            <input type="hidden" name="HPP_SHIPPING_COUNTRY" v-bind:value="payment.HPP_SHIPPING_COUNTRY">
            <input type="hidden" name="HPP_ADDRESS_MATCH_INDICATOR" v-bind:value="payment.HPP_ADDRESS_MATCH_INDICATOR">
            <input type="hidden" name="HPP_CHALLENGE_REQUEST_INDICATOR" value="NO_PREFERENCE">
            <!-- End 3D Secure 2 Mandatory and Recommended Fields -->
            <!-- Begin Fraud Management and Reconciliation Fields -->
            <input type="hidden" name="BILLING_CODE" v-bind:value="payment.BILLING_CODE">
            <input type="hidden" name="BILLING_CO" v-bind:value="payment.BILLING_CO">
            <input type="hidden" name="SHIPPING_CODE" v-blind:value="payment.SHIPPING_CODE">
            <input type="hidden" name="SHIPPING_CO" v-blind:value="payment.SHIPPING_CO">
            <input type="hidden" name="CUST_NUM" value="6e027928-c477-4689-a45f-4e138a1f208a">
            <input type="hidden" name="VAR_REF" value="Acme Corporation">
            <input type="hidden" name="PROD_ID" value="SKU1000054">
            <!-- End Fraud Management and Reconciliation Fields -->
            <input type="hidden" name="MERCHANT_RESPONSE_URL" value="https://proofs.bigprintshop.co.uk/scripts/response.php">
            <input type="hidden" name="CARD_PAYMENT_BUTTON" v-bind:value="'Pay'">
            <input type="hidden" name="CUSTOM_FIELD_NAME" value="Custom Field Data">
            <input type="hidden" name="SHA1HASH" v-bind:value="payment.SHA1HASH">
            <button id="payNow" type="submit" >Pay by card</button>
            </form>
        </div>
        <div class="product center" v-if="parseInt(payment.AMOUNT) > 0">
            <form method="post" action="https://www.paypal.com/cgi-bin/webscr" name="process">
                <input type="hidden" name="charset" value="utf-8">
                <input type="hidden" name="cmd" value="_ext-enter">
                <input type="hidden" name="custom" v-model="data.job">
                <input type="hidden" name="invoice" v-model="data.job">
                <input type="hidden" name="redirect_cmd" value="_xclick">
                <input type="hidden" name="item_name" value="Account payment">
                <input type="hidden" name="rm" value="2">
                <input type="hidden" name="business" value="a.newton@i4media.co.uk">
                <input type="hidden" name="amount" v-model="data.costs.outstanding">
                <input type="hidden" name="tax_cart" value="0">
                <input type="hidden" name="shipping" value="0">
                <input type="hidden" name="handling" value="0">
                <input type="hidden" name="weight_cart" value="0">
                <input type="hidden" name="currency_code" value="GBP">
                <input type="hidden" name="return" value="https://proofs.bigprintshop.co.uk/scripts/paypal-payment.php">
                <input type="hidden" name="cancel_return" value="https://proofs.bigprintshop.co.uk/">
                <input type="hidden" name="shopping_url" value="https://new.bigprintshop.co.uk">
                <input type="hidden" name="upload" value="1">
                <button type="submit" class="paypal"><img src="\images\paypal.png"></button>
            </form>  
        </div>
    </div> 
</div>   
</template>

<script>
export default {
    props: {
        data: Object,
        payment: Object
    },
    methods:{
        currency: function(value){
            return new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP',
            }).format( value )
        }
    },
    created: function() {


        let formData = new FormData();
        console.log( 'Outstanding: ' + this.data.costs.outstanding )
        formData.append( 'amount', this.data.costs.outstanding )
        formData.append( 'job', this.data.job )

        this.$http.post('https://proofs.bigprintshop.co.uk/scripts/get-payment.php', formData)
        .then(
            function(data){
                console.log( 'Creation sucessful' )
                console.log( data );
                this.payment = JSON.parse(data.bodyText);

            }, function(data){
                //fail
                console.log( 'Creation error' )
                console.log(data);
            }
        );
    }
}
</script>

<style scoped>
    .title{
        text-align: center;
    }

    .center{
        text-align: center !important;
    }

    .address{
        text-align: left;
    }


    .customer-details, .prices, .product{
        max-width: 800px;
        margin: 1rem auto;
        line-height: 1.5;
        text-align: left;;
    }

    .qty{
        width: 50px;
        display: inline-block;
    }

    .name{
        width: 650px;
        display: inline-block;
    }

    .headings{
        font-weight: 800;
    }

    .cost{
        width: 100px;
        display: inline-block;
        text-align: right;
    }

    #payNow{
        width: 20rem;
        padding: 1rem;
        font-weight: 700;
        font-size: 1rem;
    }

    .paypal{
        background-color: white;
    }

    .double{
        height: 5px;
        border: none;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        max-width: 800px;
    }

    .small{
        font-size: 0.6rem;
        color: darkblue;
    }
</style>