<template>
    <div class="header-wrapper">
        <div class="header">
            <a href="https://www.bigprintshop.co.uk/">
                <img src="/images/logo.svg">
            </a>

            <div class="title">
                Proofing System
            </div>

            <div class="contact">
                <p><strong>Advice & Sales</strong></p>
                <p>Call 0800 3457516 01562 865444</p>
            </div>

            
            <div class="call">
                <a href="tel:01562865444">
                    <svg version="1.1" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" width="39px" height="39px" viewBox="0 0 39 39" xml:space="preserve">
                        <g>
                            <g>
                                <path fill="#FFFFFF" d="M8.299,14.57c0.42-0.265,0.545-0.82,0.281-1.239c-0.264-0.419-0.819-0.545-1.239-0.28
                                    c-0.115,0.072-2.815,1.826-2.815,6.26c0,4.376,2.626,6.497,2.738,6.585c0.165,0.13,0.361,0.193,0.556,0.193
                                    c0.266,0,0.529-0.117,0.706-0.342c0.307-0.39,0.241-0.955-0.149-1.262c-0.021-0.016-2.054-1.661-2.054-5.174
                                    C6.322,15.913,8.226,14.619,8.299,14.57z"/>
                                <path fill="#FFFFFF" d="M6.328,28.345c-0.042-0.029-4.198-2.986-4.198-9.164c0-6.069,3.958-8.386,4.123-8.479
                                    c0.434-0.241,0.59-0.788,0.349-1.222c-0.24-0.434-0.787-0.59-1.221-0.349c-0.207,0.114-5.047,2.885-5.047,10.05
                                    c0,7.108,4.768,10.5,4.971,10.64c0.156,0.108,0.334,0.16,0.511,0.16c0.284,0,0.564-0.135,0.739-0.386
                                    C6.837,29.188,6.736,28.628,6.328,28.345z"/>
                                <path fill="#FFFFFF" d="M34.475,19.689c0-4.375-2.626-6.497-2.738-6.585c-0.39-0.307-0.955-0.24-1.262,0.149
                                    c-0.308,0.389-0.241,0.955,0.148,1.261c0.021,0.017,2.054,1.661,2.054,5.174c0,3.398-1.903,4.692-1.978,4.741
                                    c-0.419,0.265-0.545,0.82-0.28,1.24c0.171,0.271,0.462,0.418,0.761,0.418c0.163,0,0.329-0.044,0.479-0.139
                                    C31.774,25.877,34.475,24.123,34.475,19.689z"/>
                                <path fill="#FFFFFF" d="M33.695,9.179c-0.408-0.284-0.968-0.182-1.25,0.226c-0.283,0.408-0.183,0.967,0.226,1.25
                                    c0.042,0.029,4.198,2.986,4.198,9.165c0,6.069-3.958,8.385-4.122,8.479c-0.435,0.241-0.591,0.788-0.35,1.222
                                    c0.164,0.295,0.471,0.462,0.786,0.462c0.148,0,0.298-0.037,0.436-0.113c0.206-0.115,5.047-2.885,5.047-10.05
                                    C38.666,12.711,33.898,9.319,33.695,9.179z"/>
                                <path fill="#FFFFFF" d="M25.507,1.531h-1.835h-8.345h-1.834c-1.636,0-2.977,1.34-2.977,2.978v29.982
                                    c0,1.638,1.341,2.978,2.977,2.978h12.015c1.638,0,2.977-1.34,2.977-2.978V4.509C28.483,2.872,27.145,1.531,25.507,1.531z
                                        M26.986,34.658c0,0.724-0.589,1.313-1.313,1.313H13.325c-0.724,0-1.313-0.589-1.313-1.313V4.342c0-0.724,0.589-1.313,1.313-1.313
                                    l1.894-0.002c0.083,0,0.149,0.067,0.158,0.149c0.074,0.632,0.611,1.143,1.259,1.143h5.728c0.648,0,1.184-0.512,1.258-1.144
                                    c0.01-0.083,0.076-0.149,0.158-0.149l1.895,0.002c0.724,0,1.313,0.589,1.313,1.313V34.658z"/>
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
            

        </div>
    </div>
</template>

<script>
export default {
    
} 
</script>

<style scoped>
    .header-wrapper{
        widows: 100%;
        background: red;
    }

    .header{
        position: relative;
        box-sizing: border-box;
        width: 100%;
        max-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;
        margin: auto;
    }
    .title{
        color: white;
        font-weight: 700;
        font-size: 1.3rem;
        margin: 1rem;
    }
    .contact{
        position: absolute;
        right:1rem;
        top:1rem;
        color: white;
        text-align: right;
        font-size: 1.3rem;
        font-weight: 500;
        width: 30vw;
    }
    .call{
        position: absolute;
        right: 1rem;
        top: 1rem;
        display: none;
    }
    p{
        margin: 0px;
    }
    strong{
        font-weight: 800;
        font-size: 1.6rem;
    }

    @media screen and (max-width: 767px){
        .contact{
            display: none;
        }
        .call{
            display: block;
        }
    }
</style>