<template>
    <div class="vld-parent">

        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage"
        color="red"></loading>

        <p>Please enter the supplied job number and passcode below</p>
        <label>Job Number</label>
        <input type="text" id="jobNumber" v-model="jobNumber"/>
        <label>Passcode</label>
        <input type="password" id="passcode" v-model="code"/>
        <button @click="login">Sign In</button>
        <p>This website has been setup to enable you to more easily request corrections and sign off orders placed with Big Print Shop or i4Media Ltd. If you are experiencing any issues please call our expert team on 01562 865444. </p>
    </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props:{
        devMode: Boolean
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            jobNumber: '',
            code: ''
        }
    },
    components: {
        Loading
    },
    methods:{
        login: function() {

            var formData = new FormData();

            var JobNumber = this.jobNumber
            var passcode = this.code

            formData.append('jobNumber', JobNumber);
            formData.append('passcode', passcode);       
            
            this.isLoading = true; /* https://www.npmjs.com/package/vue-loading-overlay */
            
            if( ! this.devMode )
            {
                this.$http.post('https://proofs.bigprintshop.co.uk/scripts/check.php', formData)
                    .then(
                        function(data){
                            //sucess
                            if( data.body === "1" )
                            {
                                this.isLoading = false;
                                this.$emit( 'loggedin', {
                                    job : JobNumber,
                                    code : passcode
                                });
                            } else {
                                this.isLoading = false;
                                alert('Password / Job number incorrect')
                                console.log( 'Log in failed: ' + data.body);
                            }
                        }, function(data){
                            //fail
                            this.isLoading = false;
                            console.log( 'Log in failed: ' + data.body);
                        }
                    );
            } else {
                this.$emit( 'loggedin', {
                    job : JobNumber,
                    code : passcode
                });   
            }

            
        }   
    },
    mounted: function()  {
        let uri = window.location.href.split('?');

        if (uri.length == 2)
        {
            let vars = uri[1].split('&');
            let getVars = {};
            let tmp = '';

            vars.forEach(function(v){
                tmp = v.split('=');
                if(tmp.length == 2)
                getVars[tmp[0]] = tmp[1];
            });

            console.log(getVars);

            this.jobNumber = getVars.job
            this.code = getVars.code

            this.login()
            
        }
    }
}
</script>

<style scoped>
    p{
        color: black;
        text-align: center;
        font-size: 1rem;
        max-width: 70vw;
        margin: auto;
    }

    .login{
        margin-top: 2rem;
    }

    label{
        text-align: center;
        display: block;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }

    input, button{
        display: block;
        width: 16rem;
        padding: 0.5rem;
        margin: auto;
    }

    button{
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-weight: 700;
        border: none;
        background: red;
        color: white;

    }

</style>