<template>
    <div>
        <div class="content">
            <p>Hello {{ data.name }}</p>
            <p>Please find below job details and proofs. If any of this information is incorrect please email your designer at and they will arrange for this to be amended. If this job details are incorrect or you have any concerns please do not approve the job and contact your designer.</p>
            <p>If corrections to the proofs are required please click the Requested Changes button and describe the corrections required.</p>
            <p>Once you have checked the details and proofs please type "Approved" into the box at the bottom of this page.</p>
            <p>Please pay particular attention when checking all pages of supplied proofs. No liability for errors will be accepted by i4Media Ltd or Big Print Shop after artwork has been approved. This includes spelling, grammar, phone numbers, fax numbers, email addresses, website addresses as well as graphical elements. We do are not responsible for any errors printed once the documents have been approved.</p>
            <p>If this is after 11am it will count as next working day. All proofs MUST be signed off by using the online system, email or fax. Verbal confirmation is not accepted.</p>
            <p>This job is still showing an amount outstanding. Both payment and confirmation is required before the job can proceed. Delays in confirmation or payment will delay the production and dispatch of the products in question.</p>
            <div class="boxes">
                <div class="box">
                    <p class="box-title">Invoice address</p>
                    <div class="address">
                        <tamplate v-if="data.company_name">{{ data.company_name }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line1">{{ data.invoice.line1 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line2">{{ data.invoice.line2 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line3">{{ data.invoice.line3 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.line4">{{ data.invoice.line4 }}<br /></tamplate>
                        <tamplate v-if="data.invoice.postcode">{{ data.invoice.postcode }}</tamplate>
                    </div>
                    <button @click="changeInvoiceAddress()">Requested Change</button>
                </div>
                <div class="box" v-if="data.delivery.line1">
                    <p class="box-title" v-if="data.delivery.service">{{ data.delivery.service }}</p>
                    <p class="box-title" v-else>{{ data.delivery.type }}</p>
                    <div class="address" v-if="data.delivery.line1 !== ''">

                        <template v-if="!( (data.delivery.rmv_comp_name == 'on') || (data.delivery.line1 == 'Big Print Production, Rear of unit 2') )">
                            {{ data.company_name }}<br />
                        </template>

                        <tamplate v-if="!( (!data.delivery.delivery_contact) || (data.delivery.line1 == 'Big Print Production, Rear of unit 2') )">
                            FAO {{ data.delivery.delivery_contact }}<br />
                        </tamplate>

                        <template v-if="data.delivery.line1">{{ data.delivery.line1 }}<br /></template>
                        <template v-if="data.delivery.line2">{{ data.delivery.line2 }}<br /></template>
                        <template v-if="data.delivery.line3">{{ data.delivery.line3 }}<br /></template>
                        <template v-if="data.delivery.line4">{{ data.delivery.line4 }}<br /></template>
                        <template v-if="data.delivery.postcode">{{ data.delivery.postcode }}</template>

                    </div>
                    <button @click="changeDeliveryAddress()">Requested Change</button>
                </div>
            </div>
            <hr />
            <div v-for="product in data.products" :key="product.id">
                <div class="product">
                    <div>
                        Product : {{ product.product_name }} <br />
                        Size : {{ product.size_notes }} <br />
                        Finish : {{ product.finsh_notes }} <br />
                        Quantity : {{ product.qty }} <br />
                        Unit cost : {{ currency( product.unit_cost ) }} <br />
                        Total Cost : {{ currency( product.line_cost ) }} <br />
                        <div v-html="'<br /><u>Details</u><br />' + product.notes + '<br />'" v-if="product.notes.length > 0"></div>
                    </div>

                    <div class="images">
                        <div class="image" v-for="image in product.images" :key="image.filename">

                            <img v-bind:src="'data:image/jpeg;base64,'+image.base64" @click="showPreviewImage(image.base64)" />

                            <p v-if="image.qty !== ''">Quantity: {{ image.qty }}</p>

                            <p><pre>{{ image.notes }}</pre></p>

                            <UploadFile v-bind:productId="product.id" v-bind:fileName="image.filename.replace('.', '-')"></UploadFile>

                            <button v-if="data.costs.artwork > 0" @click="requestChanges(product.id, image.filename, image.base64)">Request Changes</button><br />

                        </div>
                    </div>
                </div>
            </div>

            <div class="footerwrapper">

                <div class="costs">
                    <div v-if="data.costs.artwork > 0" class="cost">
                        <div>Studio Charge</div> <div>{{ currency( parseFloat(data.costs.artwork).toFixed(2) ) }}</div>
                    </div>
                    <div class="cost">
                        <div>Line Total</div> <div>{{ currency( parseFloat( data.costs.line )) }}</div>
                    </div>
                    <div v-if="data.costs.discount > 0" class="cost">
                        <div>Discount</div> <div>{{ currency( parseFloat(data.costs.discount * data.costs.line).toFixed(2) ) }}</div>
                    </div>
                    <div v-if="data.costs.delivery > 0" class="cost">
                        <div>Delivery</div> <div>{{ currency( data.costs.delivery ) }}</div>
                    </div>
                    <div v-if="data.costs.discount > 0" class="cost">
                        <div>Sub Total</div> <div>{{ currency( data.costs.total ) }}</div>
                    </div>
                    <div class="cost">
                    <div>VAT</div> <div>{{ currency( data.costs.vat ) }}</div>
                    </div>
                    <div class="totalcost">
                        <div>Total</div> <div>{{ currency( data.costs.totalInc ) }}</div>
                    </div>
                    <div class="totalcost">
                        <div>Total Outstanding</div> <div>{{ currency( data.costs.outstanding ) }}</div>
                    </div>
                </div>

                <div class="aprovalWindow">
                    <p class="warning" v-if="data.costs.outstanding > 0">This job is still showing an amount outstanding. Both payment and confirmation is required before the job can proceed. Delays in payment will delay the production and dispatch of the products in question. If payment is made by BAC's please email your designer proof of payment to ensure there is no delay in delivery. </p>
                    <p><input type="checkbox" ref="tc" @click="changeTc()">I agree to both the <a href="https://www.bigprintshop.co.uk/terms-conditions">Terms and Conditions</a> and the <a href="https://www.bigprintshop.co.uk/requirements-of-order">Requirements of Order</a> as stated on the Big Print Shop website</p>
                    <p><input type="checkbox" ref="news">I would like to subscribe to the Big Print Shop mailing list so I can receive news and vouchers in order to keep up to date and save money on future orders. </p>
                    <p v-if="!this.tc" class="greenButton"><strong>Please click the "I agree to both the Terms and Conditions and the Requirements of Order" checkbox above</strong></p>
                    <p class="greenButton"><button class="green" @click="approve()" :disabled="!this.tc" >Approve</button></p>
                    <p>i4Media TA Big Print Shop has no responsibility errors still present in the above proofs after sign-off</p>
                </div>

            </div>


            
        </div>
        
        




        <!--Preview window-->

        <div v-if="previewWindow" class="preview-container">
            <div class="preview" ref="preview">

                <!--Invoice address-->
                <form id="invoiceAddress" v-if="invoiceAddress">
                    <p>Please note that changes to the invoice address will affect all future and current orders. If you want to use a different invoice address as a different company please email the studio at sales@bigprintshop.co.uk and quote job number {{ data.job }}</p>
                    <input v-model="data.invoice.line1" type="text" ref="invoiceAddress1">
                    <input v-model="data.invoice.line2" type="text" ref="invoiceAddress2">
                    <input v-model="data.invoice.line3" type="text" ref="invoiceAddress3">
                    <input v-model="data.invoice.line4" type="text" ref="invoiceAddress4">
                    <input v-model="data.invoice.postcode" type="text" ref="invoiceAddressPostcode">
                    <button @click.prevent="updateInvoiceAddress()">Submit</button>
                </form>

                <!--Delivery address-->
                <form id="invoiceAddress" v-if="deliveryAddress">
                    <p>Please enter new details below and click submit</p>
                    <input v-model="data.delivery.line1" type="text" ref="deliveryAddress1">
                    <input v-model="data.delivery.line2" type="text" ref="deliveryAddress2">
                    <input v-model="data.delivery.line3" type="text" ref="deliveryAddress3">
                    <input v-model="data.delivery.line4" type="text" ref="deliveryAddress4">
                    <input v-model="data.delivery.postcode" type="text" ref="deliveryAddressPostcode">
                    <button @click.prevent="updateDeliveryAddress()">Submit</button>
                </form>

                <!--Image Preview-->
                <div class="preview-image" v-if="previewImage">
                    <img v-bind:src="'data:image/jpeg;base64,'+previewImageData" @click="closePreviewImage()">
                </div>

                <!--Request Changes-->
                <div class="request-changes" v-if="previewRequestChanges">
                    <img v-bind:src="'data:image/jpeg;base64,'+previewImageData">
                    <form>
                        <p>Please be aware requesting the studio to make changes / additional changes may result in an increase in the studio charge</p>
                        <textarea ref="changesTextarea"></textarea>
                        <button @click.prevent="submitChanges()">Submit</button>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import UploadFile from './upload-file.vue';

export default {
    props: {
        data: Object,
        previewWindow: Boolean,
        changeRequested: Boolean,
        invoiceAddress: Boolean,
        deliveryAddress: Boolean,
        previewImage: Boolean,
        previewImageData: String,
        previewRequestChanges: Boolean,
        id: String,
        name: String,
        devMode: Boolean
    },
    methods: {
        submitChanges()
        {
            let formData = new FormData();
            formData.append('productId', this.id );
            formData.append('filename', this.name );
            formData.append('jobId', this.data.job );
            formData.append('change', this.$refs.changesTextarea.value );

            /**
             * Submit form data to server
             */
            this.$http.post('https://proofs.bigprintshop.co.uk/scripts/submit-changes.php', formData)
            .then(
                function(data){
                    console.log(data);
                    alert( 'Your request has been forwarded to the design studio' )
                }, function(data){
                    //fail
                    console.log(data);
                }
            );


            this.previewWindow = false;
            this.previewRequestChanges = false;
        },
        requestChanges(id, name, image)
        {
            this.previewImageData = image;
            this.previewWindow = true;
            this.previewRequestChanges = true;
            this.id = id;
            this.name = name;
        },
        showPreviewImage(data)
        {
            this.previewImageData = data;
            this.previewWindow = true;
            this.previewImage = true;
        },
        closePreviewImage()
        {
            this.previewWindow = false;
            this.previewImage = false;
        },
        changeInvoiceAddress()
        {
            this.previewWindow = true;
            this.invoiceAddress = true;
        },
        updateInvoiceAddress()
        {
            let formData = new FormData();
            formData.append('line1', this.$refs.invoiceAddress1.value );
            formData.append('line2', this.$refs.invoiceAddress2.value );
            formData.append('line3', this.$refs.invoiceAddress3.value );
            formData.append('line4', this.$refs.invoiceAddress4.value );
            formData.append('postcode', this.$refs.invoiceAddressPostcode.value);
            formData.append('job', this.data.job);

            /**
             * Submit form data to server
             */
            this.$http.post('https://proofs.bigprintshop.co.uk/scripts/update-invoice.php', formData)
                .then(
                    function(data){
                        console.log(data)
                        alert('Invoice address updated')
                    }, function(data){
                        //fail
                        console.log(data);
                    }
                );

            this.previewWindow = false;
            this.invoiceAddress = false;
        },
        changeDeliveryAddress()
        {
            this.previewWindow = true;
            this.deliveryAddress = true;
        },
        updateDeliveryAddress()
        {
            let formData = new FormData();
            formData.append('line1', this.$refs.deliveryAddress1.value );
            formData.append('line2', this.$refs.deliveryAddress2.value );
            formData.append('line3', this.$refs.deliveryAddress3.value );
            formData.append('line4', this.$refs.deliveryAddress4.value );
            formData.append('postcode', this.$refs.deliveryAddressPostcode.value);
            formData.append('job', this.data.job);

            /**
             * Submit form data to server
             */
            this.$http.post('https://proofs.bigprintshop.co.uk/scripts/update-delivery.php', formData)
                .then(
                    function(data){
                        console.log(data)
                        alert('Invoice address updated')
                    }, function(data){
                        //fail
                        console.log(data);
                    }
                );

            this.previewWindow = false;
            this.deliveryAddress = false;
        },
        closePreviewWindow()
        {
            this.previewWindow = false;
        },
        approve()
        {
            if(this.$refs.tc.checked){
                /**
                 * Submit data to server
                 */
                let formData = new FormData();

                formData.append( 'id', this.data.job );

                this.$http.post('https://proofs.bigprintshop.co.uk/scripts/approve.php', formData)
                .then(
                    function(data){
                        this.$emit('approved')
                        console.log(data);
                    }, function(data){
                        //fail
                        console.log(data);
                    }
                );
            } else {
                alert( 'Please tick the "I agree to Terms and Conditions" checkbox above' );
            }
        },
        changeTc()
        {
            this.tc = this.$refs.tc.checked;
        },
        currency: function(value){
            return new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP',
            }).format( value )
        }
    },
    components: {
        UploadFile,
    },
    data: function()
    {
        return {
            tc : false
        }
    }

}
</script>

<style scoped>
    .content{
        margin: 1rem auto;
        width: 1000px;
        max-width: calc(100% - 2rem);
    }

    hr{
        border: none;
        border-top: 1px grey dashed;
        margin: 1rem auto;
        max-width: 950px;
    }

    .product{
        margin: 2rem 0;
        border-bottom: gray dashed 1px;
        display: flex;
        flex-wrap: wrap;
    }

    .images{
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .image{
        padding: 1rem;
    }

    .image img{
        max-height: 250px;
        border: grey dashed 1px;
        max-width: 100%;
    }

    .preview-container{
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .preview{
        animation-name: fadein;
        animation-duration: 500ms;
    }

    @keyframes fadein {
    from {opacity: 0; transform: scale(0);}
    to {opacity: 1;transform: scale(1);}
    }

    input{
        display: block;
        width: calc(100% - 1rem);
        padding: 0.5rem;
        margin: 0.5rem;
        
    }

    .preview-image{
        margin: 0;
        padding: 0;
        max-width: calc(94vw);
        max-height: calc(94vh);
    }

    .preview-image img{
        max-width: calc(94vw);
        max-height: calc(94vh);
        border: 1px dashed black;
    }

    .request-changes{
        display: flex;
    }

    .request-changes textarea{
        width: 100%;
        height: 80%;
    }

    .request-changes form{
        width: 100%;
        margin: 2rem;
    }

    .request-changes img{      
        max-height: calc(50vh - 2rem);
        max-width: calc(50vw - 2rem);
    }

    .aprovalWindow{
        width: 100%;
        margin: 1rem auto;
    }

    .aprovalWindow input[type="checkbox"]{
        width: 1rem;
        display: inline;
        text-align: left;
        padding: 10rem;
    }

    .aprovalWindow p{
        width: 100%;
    }

    .greenButton{
        text-align: center;
    }

    .green{
        background: #8dc63f;
        max-width: 20rem;
    }

    .green:disabled{
        opacity: 0.25;
    }

    .costs{
        width: 50%;
        max-width: 400px;
        margin:1rem;
        padding: 1rem;
        border: 2px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cost{
        padding: 0.5rem 0;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
    }

    .totalcost{
        padding: 0.5rem 0;
        border-top: 1px solid black;
        margin-top: 0.25rem;
        display: flex;
        justify-content: space-between;
    }

    .footerwrapper{
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 1000px){
        .request-changes{
            flex-direction: column;
        }

        .request-changes img{      
            max-height: calc(50% - 2rem);
            max-width: calc(100% - 2rem);
        }

        .request-changes form{
            margin: 0;
            height: 30vh;
        }
    }

    @media (max-width: 767px){
        .footerwrapper{
            flex-direction: column;
        }

        .costs{
            margin: 1rem auto;
            width: 100%;
        }
    }

</style>